import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CreatePostResidenceModel, CreateResidenceFormModel } from '../../../types/logInModel'
import { configEnum } from '../../../enums/fetchFactoryEnum'
import activateStatusEnum from '../../../enums/activateStatusEnum'
import { saveInteractions } from '../../gtm/utils/gtmCallback'
import errorCatcher from '../../../utils/errorCatcher'
import { isEmailOk } from '../../../utils/formUtils'
import { Field, Form } from 'react-final-form'
import FormRadioGroup from '../../../components/fields/FormRadioGroup'
import { makeStyles } from '@material-ui/core'
import styles from '../../../styles'
import { prospectTypeEnum } from '../enums/prospectTypeEnum'
import CreateAccountCampusForm from '../campus/CreateAccountCampusForm'
import CreateAccountResidenceForm from './CreateAccountResidenceForm'
import { EN, EN_LANGUAGE, FR } from '../CreateAccountPage'
import linksEnum from '../../../enums/linksEnum'
import { useLocation } from 'react-router'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	fieldStyle: {
		paddingBottom: 15
	},
	titreFormulaire: {
		marginBottom: 20
	}
}))

interface AcceptingProps {
	initialValues?: CreateResidenceFormModel,
	setLoading: (loading: boolean) => void,
	setEmailSent: (email: string) => void,
	setIncorrectCode: (isCodeIncorrect: boolean) => void,
	setErrorMessage: (errorMessage: string) => void
}

type CreateAccountResidencePageProps = AcceptingProps

const REQUIRED_ACCOUNT_FIELDS = ['socialReason', 'email', 'mobile']
const REQUIRED_CONTACT_FIELDS = ['civility', 'firstname', 'lastname', 'email', 'mobile']

const CreateAccountResidencePage: React.FC<CreateAccountResidencePageProps> = (
	{
		initialValues,
		setLoading,
		setEmailSent,
		setIncorrectCode,
		setErrorMessage
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const location = useLocation()
	const dispatch = useDispatch()
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const onSubmit = (values: CreateResidenceFormModel) => {
		setLoading(true)
		const dataRegister: CreatePostResidenceModel = {
			...values,
			isResidence: true,
			locale: window.navigator.language.startsWith(EN_LANGUAGE) ? EN : FR
		}

		fetch('/api/login/okta/registerAcquisition',
			{
				body: JSON.stringify(dataRegister),
				method: configEnum.post
			})
			.then((response: Response) => response.json())
			.then((createResponse: any) => {
				switch (createResponse) {
					case activateStatusEnum.OK:
						setEmailSent(values.email)

						// @ts-ignore
						window.gtag_report_conversion()

						saveInteractions(
							{
								category: 'Espace YouFirst',
								action: 'Creation Espace You First',
								label: 'Creation Espace You First::etape 1::creer votre compte::OK'
							}
						)
						break
					case activateStatusEnum.formatEmail:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.formatEmail',
									defaultMessage: 'Format du courriel incorrect',
									description: 'Email format incorrect'
								}
							)
						)
						break
					case activateStatusEnum.creationNonAuthorized:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.creationNonAuthorized',
									defaultMessage: 'Création de compte non autorisée pour ce courriel',
									description: 'Email creation unauthorized'
								}
							)
						)
						break
					case activateStatusEnum.temporaryEmail:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.temporaryEmail',
									defaultMessage: 'Veuillez ne pas utiliser une adresse mail temporaire pour votre compte YouFirst',
									description: 'Temporary email'
								}
							)
						)
						break
					case activateStatusEnum.fullInbox:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.fullInbox',
									defaultMessage: 'Le courriel indiqué ne peut recevoir de nouveau message',
									description: 'Inbox is full'
								}
							)
						)
						break
					case activateStatusEnum.emailNotFound:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.emailNotFound',
									defaultMessage: 'Le courriel indiqué n\'a pas été trouvé pour ce domaine',
									description: 'Email not found in domain'
								}
							)
						)
						break
					case activateStatusEnum.invalidPhone:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.invalidPhone',
									defaultMessage: 'Le numéro de téléphone n\'est pas valide',
									description: 'Invalid phone'
								}
							)
						)
						break
					default:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage({
								id: 'errors.creationEmailCodeKO',
								defaultMessage: 'Une erreur est survenue, veuillez vous rapprocher de votre interlocuteur YouFirst ou réessayer plus tard.',
								description: 'Email already exist failed message'
							})
						)
				}
			})
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'register')
					)
					setIncorrectCode(true)
					setErrorMessage(
						intl.formatMessage({
							id: 'errors.creationEmailCodeKO',
							defaultMessage: 'Une erreur est survenue, veuillez vous rapprocher de votre interlocuteur YouFirst ou réessayer plus tard.',
							description: 'Email already exist failed message'
						})
					)
				}
			).finally(() => setLoading(false))

	}

	const validate = (
		values: CreateResidenceFormModel
	) => {
		const errors: Partial<{
			[key in keyof CreateResidenceFormModel]: string
		}> = {}

		const requiredEnum = values.prospectType === prospectTypeEnum.contact ? REQUIRED_CONTACT_FIELDS : REQUIRED_ACCOUNT_FIELDS
		requiredEnum.forEach((field) => {
			// @ts-ignore
			if (!values[field]) {
				errors[field as keyof typeof errors] = intl.formatMessage(
					{
						id: 'errors.required',
						defaultMessage: 'Requis*',
						description: 'Required message'
					}
				)
			}
		})

		if (!!values.email && !isEmailOk(values.email)) {
			errors.email = intl.formatMessage(
				{
					id: 'errors.formatEmail',
					defaultMessage: 'Format du courriel incorrect',
					description: 'Format email error'
				}
			)
		}

		return errors
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={
				initialValues ??
				{
					prospectType: prospectTypeEnum.contact,
					civility: 'Mme'
				}
			}
			render={
				(
					{
						handleSubmit,
						values
					}
				) => (
					<form
						onSubmit={handleSubmit}
					>
						{
							location.pathname === linksEnum.activateAccount && (
								<div
									className={
										classNames(
											'col-xs-12',
											classes.colorBlue,
											classes.text8,
											classes.titreFormulaire
										)
									}
								>
									<FormattedMessage
										id="formulairePartenaire.titre"
										defaultMessage="Complétez vos informations et activez votre compte YouFirst afin de prendre votre rendez-vous de visite."
										description="Titre du formulaire partenaire"
									/>
								</div>
							)
						}
						{/* Radio pour définir si l'utilisateur est de type physique ou moral */}
						<div className="col-xs-12">
							<div className={classes.fieldStyle}>
								<Field
									name="prospectType"
									component={FormRadioGroup}
									items={[
										{
											label: intl.formatMessage({
												id: 'createAccount.residence.prospectType.contact',
												defaultMessage: 'Particulier',
												description: 'Prospect Type Contact radio label'
											}),
											value: prospectTypeEnum.contact,
										},
										{
											label: intl.formatMessage({
												id: 'createAccount.residence.prospectType.account',
												defaultMessage: 'Professionnel',
												description: 'Prospect Type Account radio label'
											}),
											value: prospectTypeEnum.account
										}
									]}
									textStyle={isLargeScreen ? 'text1' : 'text2'}
									textColor="colorBlue"
									textWeight="medium"
									margin={false}
									type="radio"
									rowDirection
									spaceBetween={false}
								/>
							</div>
						</div>

						{
							(values.prospectType === prospectTypeEnum.contact || !values.prospectType) ? (
								<CreateAccountCampusForm />
							) : (
								<CreateAccountResidenceForm />
							)
						}
					</form>
				)}
		/>
	)
}

export default CreateAccountResidencePage
