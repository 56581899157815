import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from '../../styles'
import Button from '../../components/button/Button'
import Link from '../../components/link/Link'
import { Box } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import { submitModel, submitPostModel } from '../../types/logInModel'
import { configEnum } from '../../enums/fetchFactoryEnum'
import errorCatcher from '../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import loginStatusEnum from '../../enums/loginStatusEnum'
import ErrorCard from '../../components/errorCard/ErrorCard'
import links from '../../enums/linksEnum'
import { useHistory } from 'react-router'
import youfirstEnvEnum from '../../enums/youfirstEnvEnum'
import Loader from '../../components/loader/Loader'
import { saveInteractions } from '../gtm/utils/gtmCallback'

const CODE_VERIFIER: string = 'code_verifier'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    titleStyle: {
        padding: '15px 0px',
        display: 'flex',
        justifyContent: 'center'
    },
    betweenField: {
        paddingBottom: 15
    },
    linkStyle: {
        padding: '15px 0px'
    },
    activateStyle: {
        marginBottom: 30,
        marginTop: 15
    },
    activateTextStyle: {
        marginTop: 10
    }
}))

const LoginPage: React.FC = () => {
    const classes = useStyles()
    const theme = useTheme()
    const intl = useIntl()
    const history = useHistory()
    const dispatch = useDispatch()
    const [incorrectId, setIncorrectId] = useState<boolean>(false)
    const isAcquisition = window.location.pathname.includes(youfirstEnvEnum.ACQUISITION)
    const isAcquisitionResidence = window.location.pathname.includes(youfirstEnvEnum.ACQUISITION_RESIDENCE)
    const [loading, setLoading] = React.useState(false)

    const titleClass: ClassValue = classNames(
        'col-xs-12',
        classes.titleStyle,
        classes.heavy,
        classes.title4,
        classes.colorBlue
    )

    const activateClass: ClassValue = classNames(
        classes.colorBlue,
        classes.text3,
        classes.activateTextStyle
    )

    const containerErrorClass: ClassValue = classNames(
        'col-xs-12',
        classes.betweenField
    )

    const onSubmit = (values: submitModel) => {
        setLoading(true)
        const dataSignIn: submitPostModel = {
            username: values.email,
            password: values.password
        }

        fetch('/api/login/okta/login',
            {
                body: JSON.stringify(dataSignIn),
                method: configEnum.post
            })
            .then(
                (response: Response) => response.json()
                    .then(
                        (loginResponse: any) => {
                            if (!loginResponse.status) {
                                localStorage.setItem(CODE_VERIFIER, loginResponse.code_verifier)
                                saveInteractions(
                                    {
                                        category: 'Espace YouFirst',
                                        action: 'Connexion Espace YouFirst',
                                        label: 'Connexion Espace You First::OK'
                                    }
                                )

                                // @ts-ignore
                                window.fbq('track', 'CompleteRegistration')

                                window.location.href = loginResponse.redirect
                            } else {
                                switch (loginResponse.status) {
                                    case loginStatusEnum.MFA_CHALLENGE:
                                        history.push(links.mfa, {
                                            mfaInfo: {
                                                email: loginResponse.email || '',
                                                verify_url: loginResponse.verify_url || '',
                                                state_token: loginResponse.state_token || '',
                                                factor_id: loginResponse.factor_id || '',
                                                status: loginResponse.status || ''
                                            },
                                            isMfaEnroll: false
                                        })
                                        break
                                    case loginStatusEnum.MFA_ENROLL_ACTIVATE:
                                        history.push(links.mfa, {
                                            mfaInfo: {
                                                email: loginResponse.email || '',
                                                verify_url: loginResponse.verify_url || '',
                                                state_token: loginResponse.state_token || '',
                                                factor_id: loginResponse.factor_id || '',
                                                status: loginResponse.status || ''
                                            },
                                            isMfaEnroll: true
                                        })
                                        break
                                    case loginStatusEnum.FAILURE:
                                        setIncorrectId(true)
                                        break
                                }
                            }

                        }
                    )
            )
            .catch(
                (error) => {
                    dispatch(
                        errorCatcher(error, 'sign in')
                    )
                    setIncorrectId(true)
                }
            ).finally(() => setLoading(false))
    }

    const validate = (
        values: submitModel
    ) => {
        const errors: any = {}
        if (!values.email) {
            errors.email = intl.formatMessage(
                {
                    id: 'errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required message'
                }
            )
        }
        if (!values.password) {
            errors.password = intl.formatMessage(
                {
                    id: 'errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required message'
                }
            )
        }
        return errors
    }

    return (
        <>
            <div className={titleClass}>
                <FormattedMessage
                    id="authentification.title"
                    defaultMessage="Connectez-vous à votre espace"
                    description="Okta authentification title"
                />
            </div>
            {
                incorrectId &&
                <div className={containerErrorClass}>
                    <ErrorCard message={intl.formatMessage(
                        {
                            id: 'errors.loginFailed',
                            defaultMessage: 'Votre identifiant ou votre mot de passe est incorrect. Veuillez réessayer',
                            description: 'authentification failed message'
                        }
                    )}/>
                </div>
            }
            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={
                    (
                        {
                            handleSubmit
                        }
                    ) => (

                        <form
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <div className="col-xs-12">
                                    <div className={classes.betweenField}>
                                        <Field
                                            name="email"
                                            component={FormTextField}
                                            placeholder={{
                                                id: 'authentification.placeholderEmail',
                                                defaultMessage: 'Courriel',
                                                description: 'Email placeholder'
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name="password"
                                            component={FormTextField}
                                            type="password"
                                            placeholder={{
                                                id: 'authentification.placeholderPassword',
                                                defaultMessage: 'Mot de passe',
                                                description: 'Password placeholder'
                                            }}
                                        />
                                    </div>

                                    <div className={classes.linkStyle}>
                                        <Link
                                            textStyle="text8"
                                            color="colorBlue"
                                            onClick={() => history.push(links.forgottenPwd)}
                                        >
                                            <FormattedMessage
                                                id="authentification.passwordForgotten"
                                                defaultMessage="Mot de passe oublié ?"
                                                description="Authentification link to reset password"
                                            />
                                        </Link>
                                    </div>

                                    {
                                        loading ? (
                                            <Loader size={40} />
                                        ) : (
                                            <Button
                                                background="backgroundBlue"
                                                color="colorWhite"
                                                textStyle="text8"
                                                type="submit"
                                                size="mediumButton"
                                            >
                                                <FormattedMessage
                                                    id="authentification.submit"
                                                    defaultMessage="Se connecter"
                                                    description="Submit message"
                                                />
                                            </Button>
                                        )
                                    }
                                </div>
                            </div>
                        </form>
                    )}
            />
            <div className="col-xs-12">
                {isAcquisition || isAcquisitionResidence ?
                    <>
                        <div className={activateClass}>
                            <FormattedMessage
                                id="authentification.noAccount"
                                defaultMessage="Vous n'avez pas de compte ?"
                                description="no account title"
                            />
                        </div>
                        <Box border={1} borderColor={theme.palette.blue.main} className={classes.activateStyle}>
                            <Button
                                background="backgroundWhite"
                                color="colorBlue"
                                textStyle="text8"
                                onClick={() => history.push(links.createAccount)}
                            >
                                <FormattedMessage
                                    id="authentification.createAccount"
                                    defaultMessage="Créer votre compte"
                                    description="Create account button"
                                />
                            </Button>
                        </Box>
                    </>
                    :
                    <>
                        <div className={activateClass}>
                            <FormattedMessage
                                id="authentification.signUp"
                                defaultMessage="Vous n'avez pas encore activé votre compte ?"
                                description="activation title"
                            />
                        </div>
                        <Box border={1} borderColor={theme.palette.blue.main} className={classes.activateStyle}>
                            <Button
                                background="backgroundWhite"
                                color="colorBlue"
                                textStyle="text8"
                                onClick={() => history.push(links.activateAccount)}
                            >
                                <FormattedMessage
                                    id="authentification.signUpAccount"
                                    defaultMessage="Activer votre espace"
                                    description="Activate account button"
                                />
                            </Button>
                        </Box>
                    </>
                }
            </div>
        </>
    )
}

export default LoginPage
