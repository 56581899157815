import React from 'react'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { createAccountModel, createPostModel } from '../../../types/logInModel'
import { configEnum } from '../../../enums/fetchFactoryEnum'
import activateStatusEnum from '../../../enums/activateStatusEnum'
import { saveInteractions } from '../../gtm/utils/gtmCallback'
import errorCatcher from '../../../utils/errorCatcher'
import { isEmailOk } from '../../../utils/formUtils'
import { EN, EN_LANGUAGE, FR } from '../CreateAccountPage'
import { useDispatch } from 'react-redux'
import CreateAccountCampusForm from './CreateAccountCampusForm'

const REQUIRED_FIELDS = ['civility', 'firstname', 'lastname', 'email', 'mobile']

interface AcceptingProps {
	setLoading: (loading: boolean) => void,
	setEmailSent: (email: string) => void,
	setIncorrectCode: (isCodeIncorrect: boolean) => void,
	setErrorMessage: (errorMessage: string) => void
}

type CreateAccountCampusPageProps = AcceptingProps

const CreateAccountCampusPage: React.FC<CreateAccountCampusPageProps> = (
	{
		setLoading,
		setEmailSent,
		setIncorrectCode,
		setErrorMessage
	}
) => {
	const intl = useIntl()
	const dispatch = useDispatch()

	const onSubmit = (values: createAccountModel) => {
		setLoading(true)
		const dataRegister: createPostModel = {
			...values,
			locale: window.navigator.language.startsWith(EN_LANGUAGE) ? EN : FR,
			isResidence: false
		}

		fetch('/api/login/okta/registerAcquisition',
			{
				body: JSON.stringify(dataRegister),
				method: configEnum.post
			})
			.then((response: Response) => response.json())
			.then((createResponse: any) => {
				switch (createResponse) {
					case activateStatusEnum.OK:
						setEmailSent(values.email)

						// @ts-ignore
						window.gtag_report_conversion()

						saveInteractions(
							{
								category: 'Espace YouFirst',
								action: 'Creation Espace You First',
								label: 'Creation Espace You First::etape 1::creer votre compte::OK'
							}
						)
						break
					case activateStatusEnum.formatEmail:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.formatEmail',
									defaultMessage: 'Format du courriel incorrect',
									description: 'Email format incorrect'
								}
							)
						)
						break
					case activateStatusEnum.creationNonAuthorized:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.creationNonAuthorized',
									defaultMessage: 'Création de compte non autorisée pour ce courriel',
									description: 'Email creation unauthorized'
								}
							)
						)
						break
					case activateStatusEnum.temporaryEmail:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.temporaryEmail',
									defaultMessage: 'Veuillez ne pas utiliser une adresse mail temporaire pour votre compte YouFirst',
									description: 'Temporary email'
								}
							)
						)
						break
					case activateStatusEnum.fullInbox:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.fullInbox',
									defaultMessage: 'Le courriel indiqué ne peut recevoir de nouveau message',
									description: 'Inbox is full'
								}
							)
						)
						break
					case activateStatusEnum.emailNotFound:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.emailNotFound',
									defaultMessage: 'Le courriel indiqué n\'a pas été trouvé pour ce domaine',
									description: 'Email not found in domain'
								}
							)
						)
						break
					case activateStatusEnum.invalidPhone:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage(
								{
									id: 'errors.invalidPhone',
									defaultMessage: 'Le numéro de téléphone n\'est pas valide',
									description: 'Invalid phone'
								}
							)
						)
						break
					default:
						setIncorrectCode(true)
						setErrorMessage(
							intl.formatMessage({
								id: 'errors.creationEmailCodeKO',
								defaultMessage: 'Une erreur est survenue, veuillez vous rapprocher de votre interlocuteur YouFirst ou réessayer plus tard.',
								description: 'Email already exist failed message'
							})
						)
				}
			})
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'register')
					)
					setIncorrectCode(true)
					setErrorMessage(
						intl.formatMessage({
							id: 'errors.creationEmailCodeKO',
							defaultMessage: 'Une erreur est survenue, veuillez vous rapprocher de votre interlocuteur YouFirst ou réessayer plus tard.',
							description: 'Email already exist failed message'
						})
					)
				}
			).finally(() => setLoading(false))

	}

	const validate = (
		values: createAccountModel
	) => {
		const errors: any = {}

		REQUIRED_FIELDS.forEach((field: string) => {
			// @ts-ignore
			if (!values[field]) {
				errors[field] = intl.formatMessage(
					{
						id: 'errors.required',
						defaultMessage: 'Requis*',
						description: 'Required message'
					}
				)
			}
		})

		if (!!values.email && !isEmailOk(values.email)) {
			errors.email = intl.formatMessage(
				{
					id: 'errors.formatEmail',
					defaultMessage: 'Format du courriel incorrect',
					description: 'Format email error'
				}
			)
		}

		return errors
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={{
				civility: 'Mme'
			}}
			render={
				(
					{
						handleSubmit
					}
				) => (
					<form
						onSubmit={handleSubmit}
					>
						<CreateAccountCampusForm />
					</form>

				)}
		/>
	)
}

export default CreateAccountCampusPage
