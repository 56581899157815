import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import { useCookies } from 'react-cookie'
import Loader from '../../components/loader/Loader'
import links from '../../enums/linksEnum'
import { useHistory } from 'react-router'
import youfirstEnvEnum from '../../enums/youfirstEnvEnum'
import { useIntl } from 'react-intl'
import statusEnum from '../../enums/statusEnum'
import logout from '../../api/logout'

interface Store {
	errorCatcher: (error: any, id: string) => void,
}

type CheckUserProps = Store

const LAST_URL: string = 'lastUrl'
const ACCESS_TOKEN: string = 'accessToken'
const ID_TOKEN: string = 'id_token'
const SPACE: string = 'space'
const CHECK_USER = 'checkUser'

const CheckUser: React.FC<CheckUserProps> = (
	{
		errorCatcher
	}
) => {
	const history = useHistory()
	const intl = useIntl()
	const lastUrl: string = useCookies()[0][LAST_URL]
	const [loading, setLoading] = useState<boolean>(false)
	const accessToken: string | null = localStorage.getItem(ACCESS_TOKEN)
	const setCookie = useCookies([ID_TOKEN, SPACE])[1]
	const cookies = useCookies([ACCESS_TOKEN])[0]
	const removeCookies = useCookies()[2]
	const fetchRef = useRef<Promise<any>>()

	useEffect(
		() => {
			if (!accessToken) {
				history.push(links.login)
			} else {
				setLoading(true)

				if (!fetchRef.current) {
					fetchRef.current = fetch('/api/login/utilisateur/redirection',
						{
							headers: {
								'Authorization': `Bearer ${JSON.parse(`${accessToken}`).accessToken}`,
								'Content-Type': 'application/json'
							}
						}
					)
						.then(
							(response: Response) => response.json()
								.then(
									(userResponse: any) => {
										if (response.status === statusEnum.badRequest) {
											let errorMsg = ''
											if (typeof userResponse === 'string') {
												errorMsg = userResponse
											} else {
												errorMsg = intl.formatMessage({
													id: userResponse.id,
													defaultMessage: userResponse.defaultMessage,
													description: 'Error message'
												})
											}

											errorCatcher(errorMsg, CHECK_USER)

											logout(cookies, removeCookies)
												.finally(() => setLoading(false))
										} else {
											let envUrl = ''
											switch (userResponse) {
												case youfirstEnvEnum.CAMPUS:
													envUrl = JSON.parse(localStorage.getItem('commonUri') || '').campus_uri
													setCookie(SPACE, youfirstEnvEnum.CAMPUS, {
														path: '/',
														domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
														secure: true
													})
													break
												case youfirstEnvEnum.RESIDENCE:
													envUrl = JSON.parse(localStorage.getItem('commonUri') || '').residence_uri
													setCookie(SPACE, youfirstEnvEnum.RESIDENCE, {
														path: '/',
														domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
														secure: true
													})
													break
												case youfirstEnvEnum.ACQUISITION:
													envUrl = `${JSON.parse(localStorage.getItem('commonUri') || '').acquisition_uri}/${intl.locale}/dossier`
													setCookie(SPACE, youfirstEnvEnum.ACQUISITION, {
														path: '/',
														domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
														secure: true
													})
													break
												case youfirstEnvEnum.ACQUISITION_RESIDENCE:
													envUrl = `${JSON.parse(localStorage.getItem('commonUri') || '').acquisition_residence_uri}/fr/check-selection`
													setCookie(SPACE, youfirstEnvEnum.ACQUISITION_RESIDENCE, {
														path: '/',
														domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
														secure: true
													})
													break
												case youfirstEnvEnum.ACQUISITION_RESIDENCE_ATTACHED:
													envUrl = `${JSON.parse(localStorage.getItem('commonUri') || '').acquisition_residence_uri}/fr/dossier`
													setCookie(SPACE, youfirstEnvEnum.ACQUISITION_RESIDENCE, {
														path: '/',
														domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
														secure: true
													})
													break
												default:
													envUrl = ''
											}
											setCookie(ID_TOKEN, cookies[ACCESS_TOKEN].accessToken, {
												path: '/',
												domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || '',
												secure: true
											})
											window.location.href = lastUrl ? `${envUrl}${lastUrl}` : envUrl
										}
									}
								)
						)
				}
			}
		}, [errorCatcher, lastUrl, accessToken, history, setCookie, cookies, intl, removeCookies]
	)

	return (
		<>
			{
				loading && <Loader />
			}
		</>

	)
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => (
	{
		errorCatcher: (error: any, id: string) => {
			dispatch(errorCatcher(error, id))
		}
	}
)

export default connect(null, mapDispatchToProps)(CheckUser)
