import React, { useCallback, useContext, useEffect } from 'react'
import '../App.css'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'
import SnackBar from '../components/snackBar/SnackBar'
import CheckUser from '../modules/checkUser/CheckUser'
import links from '../enums/linksEnum'
import { RootState } from '../modules/reducers'
import { connect } from 'react-redux'
import { AccessibilityModel } from '../types/accessibility/accessibilityModel'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '../theme'
import CallbackLogin from '../modules/login/CallbackLogin'
import { useCookies } from 'react-cookie/cjs'
import Cgu from '../modules/cgu/Cgu'
import { LangContext } from '../components/langContext/langContext'
import FinishActivationPage from '../modules/activate/FinishActivationPage'
import ResetPassword from '../modules/resetPwd/ResetPassword'
import LoginPage from '../modules/login/LoginPage'
import LoginBackground from '../modules/login/LoginBackground'
import ActivateAccountPage from '../modules/activate/ActivateAccountPage'
import MFA from '../modules/mfa/MFA'
import NewPassword from '../modules/resetPwd/NewPassword'
import CreateAccountPage from '../modules/createAccount/CreateAccountPage'
import { configEnum } from '../enums/fetchFactoryEnum'

interface ModelAccessToken {
	accessToken: string
	authorizeUrl: string
	expiresAt: number
	scopes: string[]
	tokenType: string
	userinfoUrl: string
	value: string
}

const ACCESS_TOKEN: string = 'accessToken'
const CODE_VERIFIER: string = 'code_verifier'
const ID_TOKEN: string = 'id_token'

const loginBackground = {
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
	height: 'fit-content',
	minHeight: '100vh',
	width: 'fit-content',
	minWidth: '100vw',
	backgroundImage: 'url(\'/img/LoginPicture.jpg\')',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat'
}

type AppProps = Store

interface Store {
	accessibility: AccessibilityModel
}

function App(
	props: AppProps
) {
	const {
		accessibility
	} = props

	const history = useHistory()
	const location = useLocation()
	const { switchLang } = useContext(LangContext)
	const removeCookies = useCookies()[2]
	const accessToken: any = localStorage.getItem(ACCESS_TOKEN) || ''
	const cookies = useCookies([ACCESS_TOKEN])[0]
	const accessTokenFromCookies: ModelAccessToken = cookies[ACCESS_TOKEN]

	const clearCaches = useCallback(
		() => {
			localStorage.removeItem(ACCESS_TOKEN)
			localStorage.removeItem(CODE_VERIFIER)

			removeCookies(ID_TOKEN, {
				path: '/',
				domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
			})
			removeCookies(ACCESS_TOKEN, {
				path: '/',
				domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
			})
		}, [removeCookies]
	)

	const checkToken = useCallback(
		() => {
			fetch('/api/login/okta/checkTokenValidity',
				{
					method: configEnum.post,
					body: JSON.stringify({ token: accessTokenFromCookies.value })
				}
			)
				.then(
					(response: Response) => {
						if (response.ok) {
							response.json()
								.then(
									() => {
										history.push(links.checkUser)
									}
								)
								.catch(
									() => {
										clearCaches()
									}
								)
						} else {
							clearCaches()
						}
					}
				)
		}, [accessTokenFromCookies, history, clearCaches]
	)

	useEffect(
		() => {
			switchLang((window.navigator.language).substring(0, 2))
			if (location.pathname === links.checkUser) {
				if (accessToken) {
					removeCookies(CODE_VERIFIER, { path: '/' })
				} else {
					console.error('Une erreur est survenue lors de la récupération de l\'access token : token non défini')
					history.push(links.login)
				}
			} else if (location.pathname.includes(links.login)) {
				if (accessTokenFromCookies && 'value' in accessTokenFromCookies) {
					checkToken()
				} else {
					clearCaches()
				}
			} else if (location.pathname === links.checkOkta && history.location.hash.length === 0) {
				history.push(links.checkUser)
			}
		}, [history, location, accessToken, switchLang, removeCookies, checkToken, clearCaches, accessTokenFromCookies]
	)

	if (location.pathname === links.cgu) {
		return <MuiThemeProvider theme={createTheme(accessibility.isVisualHandicap)}>
			<Route component={() => <Cgu accessibility={accessibility} />} />
		</MuiThemeProvider>
	}

	return (
		<div style={loginBackground}>
			<MuiThemeProvider theme={createTheme(accessibility.isVisualHandicap)}>
				<LoginBackground>
					<Switch>
						<Route path={links.login} component={LoginPage} />
						<Route path={links.forgottenPwd} component={ResetPassword} />
						<Route path={links.resetPwd} component={NewPassword} />
						<Route path={links.checkOkta} component={CallbackLogin} />
						<Route path={links.mfa} component={MFA} />
						<Route path={links.activateAccount} component={ActivateAccountPage} />
						<Route path={`${links.activateAccountEnd}/:token`} component={FinishActivationPage} />
						<Route path={links.createAccount} component={CreateAccountPage} />

						<Route path={links.checkUser} component={CheckUser} />
						<Route path="/">
							<Redirect to={links.login} />
						</Route>
						<Route path="*" component={LoginPage} />
					</Switch>
				</LoginBackground>
				<SnackBar />
			</MuiThemeProvider>
		</div>
	)
}

const mapStateToProps = (state: RootState) => (
	{
		accessibility: state.accessibility
	}
)

export default connect(mapStateToProps)(App)