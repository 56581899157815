import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import { useStyle } from './style'
import { useMediaQuery } from 'react-responsive'
import parse from 'html-react-parser'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { PageModel } from '../../types/footer/footer_model'
import { AccessibilityModel } from '../../types/accessibility/accessibilityModel'
import Loader from '../../components/loader/Loader'
import Link from '../../components/link/Link'
import links from '../../enums/linksEnum'
import { useHistory } from 'react-router'
import youfirstEnvEnum from '../../enums/youfirstEnvEnum'

const ID_CGU = 'get_cgu'
const INITIAL_STATE: PageModel = {
	id: '',
	content: '',
	title: ''
}

interface Store {
	accessibility: AccessibilityModel
}

type CguProps = Store

const Cgu: React.FC<CguProps> = (
	{
		accessibility
	}
) => {
	const classes = useStyle({ accessibility })
	const intl = useIntl()
	const history = useHistory()
	const dispatch = useDispatch()
	const isAcquisition = window.location.pathname.includes(youfirstEnvEnum.ACQUISITION)
	const [cgu, setCgu] = useState<PageModel>(INITIAL_STATE)
	const [loading, setLoading] = useState<boolean>(true)

	const backToLogIn = () => {
		history.push(links.login)
	}

	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const detailContainerClass: ClassValue = classNames(
		classes.detailsContainerStyle,
		isLargeScreen ? classes.detailsContainerLargeScreenStyle : classes.detailsContainerSmallScreenStyle
	)

	const textContainerClass: ClassValue = classNames(
		classes.textContainerStyle,
		{
			[classes.textContainerLargeScreenStyle]: isLargeScreen,
			[classes.textContainerSmallScreenStyle]: !isLargeScreen
		}
	)

	const titleClass: ClassValue = classNames(
		classes.title1,
		classes.colorAccessibility,
		classes.titleStyle
	)

	useEffect(
		() => {
			const fd = new FormData()
			fd.append('route', `/${intl.locale}/api/content/cgu_acquisition`)

			const promise = isAcquisition ?
				fetch(`/api/login/drupal/exportREST`,
					{
						method: 'POST',
						body: fd
					})
				:
				fetch(`/api/login/information/getFooterInfos?language=${intl.locale}`,
					{
						headers: {
							'activityLine': 'residence'
						}
					})

			setLoading(true)
			promise
				.then(
					(response: Response) => response.json()
						.then(
							(cguResponse: PageModel[]) => {
								if (cguResponse.length > 0) {
									setCgu(cguResponse[0])
								}
							}
						)
				)
				.catch(
					(error: any) => dispatch(
						errorCatcher(error, ID_CGU)
					)
				)
				.finally(
					() => setLoading(false)
				)
		}, [dispatch, intl.locale, isAcquisition]
	)

	if (loading) {
		return <Loader />
	}

	return (
		<div className={isLargeScreen ? classes.cguContainerLargeScreenStyle : classes.cguContainerSmallScreenStyle}>
			<div className={detailContainerClass}>
				<div className={textContainerClass}>
					<div>
						<h1 className={titleClass}>{cgu.title}</h1>
						<span>
						{parse(cgu.content || '')}
					</span>
					</div>
				</div>
			</div>
			<div className={classes.linkStyle}>
				<Link
					textStyle="text8"
					color="colorBlue"
					onClick={backToLogIn}
				>
					<FormattedMessage
						id="resetPassword.backSignIn"
						defaultMessage="Retour à la connexion"
						description="Back to log in link"
					/>
				</Link>
			</div>
		</div>
	)
}

export default Cgu
