import React, { useCallback, useEffect, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router'
import styles from '../../styles'
import { displaySnackbar } from '../reducers/snackBar/actions'
import links from '../../enums/linksEnum'
import { configEnum } from '../../enums/fetchFactoryEnum'
import { FinishActivationPostModel, finishValidationModel } from '../../types/logInModel'
import errorCatcher from '../../utils/errorCatcher'
import { FormattedMessage, useIntl } from 'react-intl'
import { isPasswordOk } from '../../utils/formUtils'
import Loader from '../../components/loader/Loader'
import ErrorCard from '../../components/errorCard/ErrorCard'
import FormTextField from '../../components/fields/FormTextField'
import Button from '../../components/button/Button'
import { saveInteractions } from '../gtm/utils/gtmCallback'
import youfirstEnvEnum from '../../enums/youfirstEnvEnum'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	fieldStyle: {
		paddingBottom: 15
	},
	formContainer: {
		'& button': {
			width: '100%'
		}
	},
	textCenter: {
		textAlign: 'center'
	}
}))

interface MatchParamsModel {
	token: string
}

const GET_ACTIVATION_TOKEN = 'finish_activation'
const ACCOUNT_ACTIVATED = 'account_activated'
const FAILURE_STATUS = 'FAILURE'
const PASSWORD_NAME = 'password'
const CONFIRM_PASSWORD_NAME = 'confirmPassword'
const REGISTRATION_SUCCESS = 'SUCCESS'

const FinishActivationPage = () => {
	const classes = useStyle()
	const intl = useIntl()
	const dispatch = useDispatch()
	const history = useHistory()
	const match = useRouteMatch<MatchParamsModel>()

	const fetchRef = useRef<Promise<any>>()
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)
	const [errorMessage, setErrorMessage] = React.useState<string>('')
	const [activationToken, setActivationToken] = React.useState<string>('')
	const [loading, setLoading] = React.useState<boolean>(true)
	const [seePassword, setSeePassword] = React.useState<boolean>(false)
	const [seeConfirmPassword, setSeeConfirmPassword] = React.useState<boolean>(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.heavy,
		classes.title4,
		classes.textCenter,
		classes.colorBlue
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.fieldStyle
	)

	const redirectToLogin = useCallback(
		() => {
			dispatch(
				displaySnackbar(
					{
						id: GET_ACTIVATION_TOKEN,
						message: intl.formatMessage(
							{
								id: 'finishActivation.invalidToken',
								defaultMessage: 'Le token d\'activation n\'est pas valide ou a expiré',
								description: 'Invalid token message'
							}
						),
						open: true,
						hideIcon: true
					}
				)
			)
			history.push(links.login)
		}, [history, dispatch, intl]
	)

	useEffect(
		() => {
			const tokenParam = match.params.token

			if (!fetchRef.current) {
				fetchRef.current = fetch(
					'/api/login/okta/login',
					{
						body: JSON.stringify({ token: tokenParam }),
						method: configEnum.post
					}
				)
					.then(
						(response: Response) => response.json()
							.then(
								(activationToken: any) => {
									if (activationToken.status === FAILURE_STATUS) {
										redirectToLogin()
									} else {
										setActivationToken(activationToken.stateToken || activationToken.state_token)
									}
								}
							)
							.catch(
								() => redirectToLogin()
							)
							.finally(
								() => setLoading(false)
							)
					)
			}
		}, [match.params.token, redirectToLogin]
	)

	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault()
		return false
	}

	const onSubmit = (values: finishValidationModel) => {
		setLoading(true)
		const dataRegister: FinishActivationPostModel = {
			newPassword: values.password,
			stateToken: activationToken
		}

		fetch('/api/login/okta/recoveryPassword',
			{
				body: JSON.stringify(dataRegister),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(registerResponse: any) => {
							if (registerResponse.status === REGISTRATION_SUCCESS) {
								// Activation du profil
								fetch('/api/login/okta/activation',
									{
										body: JSON.stringify({ idOkta: registerResponse.idOkta }),
										method: configEnum.post
									}
								)
									.then(
										(activationResponse) => activationResponse.json()
											.then(
												(envResponse: any) => {
													dispatch(
														displaySnackbar(
															{
																id: ACCOUNT_ACTIVATED,
																message: intl.formatMessage(
																	{
																		id: 'finishActivation.accountActivated',
																		defaultMessage: 'Votre compte a bien été activée',
																		description: 'Account activated message'
																	}
																),
																open: true,
																hideIcon: true
															}
														)
													)

													saveInteractions(
														{
															category: 'Espace YouFirst',
															action: 'Creation Espace You First',
															label: 'Creation Espace You First::etape 2::activer votre compte::OK'
														}
													)

													setLoading(false)
													switch (envResponse) {
														case youfirstEnvEnum.ACQUISITION:
														case youfirstEnvEnum.ACQUISITION_RESIDENCE:
															window.location.href = `${window.origin}/${envResponse}${links.login}`
															break
														case youfirstEnvEnum.CAMPUS:
															history.push(links.login)
															break
													}
												}
											)
									)
							} else {
								setLoading(false)
								setIncorrectCode(true)
								setErrorMessage(
									intl.formatMessage(
										{
											id: 'finishActivation.errorUpdate',
											defaultMessage: 'Une erreur est survenue lors de la modification du mot de passe',
											description: 'Error on password update message'
										}
									)
								)
							}
						}
					)
			)
			.catch(
				(error) => {
					setLoading(false)
					dispatch(
						errorCatcher(error, 'register')
					)
				}
			)
	}

	const validate = (
		values: finishValidationModel
	) => {
		const error: any = {}

		if (!values.password) {
			error.password = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		} else if (!isPasswordOk(values.password)) {
			error.password = intl.formatMessage(
				{
					id: 'errors.formatPassword',
					defaultMessage: 'Le mot de passe doit comporter au moins 8 caractères dont au moins un chiffre, une lettre minuscule et une lettre majuscule',
					description: 'Format password error'
				}
			)
		}

		if (!values.confirmPassword) {
			error.confirmPassword = intl.formatMessage(
				{
					id: 'errors.required',
					defaultMessage: 'Requis*',
					description: 'Required message'
				}
			)
		}

		if (values.password && values.confirmPassword && values.confirmPassword !== values.password) {
			error.confirmPassword = intl.formatMessage(
				{
					id: 'errors.samePassword',
					defaultMessage: 'Les nouveaux mots de passe doivent correspondre',
					description: 'Password not same message'
				}
			)
		}

		return error
	}

	if (loading || !activationToken || activationToken.length === 0) {
		return (
			<Loader />
		)
	}

	return (
		<>
			<div>
				<div className={titleClass}>
					<FormattedMessage
						id="finishActivation.title"
						defaultMessage="Finaliser l'activation de votre compte"
						description="Title message"
					/>
				</div>

				{
					incorrectCode &&
					<div className={containerErrorClass}>
						<ErrorCard message={errorMessage} />
					</div>
				}

				<Form
					onSubmit={onSubmit}
					validate={validate}
					render={
						(
							{
								handleSubmit
							}
						) => (

							<form
								onSubmit={handleSubmit}
							>
								<div className={classes.formContainer}>
									<div className="col-xs-12">
										<div className={classes.fieldStyle}>
											<Field
												name={PASSWORD_NAME}
												component={FormTextField}
												placeholder={
													{
														id: 'finishActivation.password',
														defaultMessage: 'Mot de passe',
														description: 'Password placeholder'
													}
												}
												type={seePassword ? 'text' : 'password'}
												showPassword
												seePassword={seePassword}
												handleClickShowPassword={() => setSeePassword(!seePassword)}
											/>
										</div>
										<div className={classes.fieldStyle}>
											<Field
												name={CONFIRM_PASSWORD_NAME}
												component={FormTextField}
												placeholder={
													{
														id: 'finishActivation.confirmPassword',
														defaultMessage: 'Confirmation mot de passe',
														description: 'Confirm password placeholder'
													}
												}
												inputOnPaste={onPaste}
												type={seeConfirmPassword ? 'text' : 'password'}
												showPassword
												seePassword={seeConfirmPassword}
												handleClickShowPassword={() => setSeeConfirmPassword(!seeConfirmPassword)}
											/>
										</div>

										<Button
											background="backgroundBlue"
											color="colorWhite"
											type="submit"
											textStyle="text8"
										>
											<FormattedMessage
												id="activateAccount.activate"
												defaultMessage="Activer le compte"
												description="Activation button"
											/>
										</Button>
									</div>
								</div>
							</form>
						)
					}
				/>
			</div>
		</>
	)
}

export default FinishActivationPage
